@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');


html {
  scroll-behavior: smooth;
}

body {
  font-family: "Space Grotesk", sans-serif;
  background: #000;
  min-height: 100vh;
  position: relative;

  &::-webkit-scrollbar {
    width: 5px;
  }


  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }


  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 10px;
  }


  &::-webkit-scrollbar-thumb:hover {
    background: #808080;
  }
}

.nav-container {
  max-width: 1460px;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
}

@media (max-width:600px) {
  body {
    padding: 0px;
  }

  .nav-container {
    max-width: 1460px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
  }
}

.custom-container {
  max-width: 1300px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}


.grey {
  color: #888 !important;
}



.ptb20 {
  padding: 20px 0;
}




.ptb60 {
  padding: 60px 0;
}






.cursor-pointer {
  cursor: pointer;
}

.purple {
  color: #6c01c3;
}

.yellow {
  color: #ffc13d;
}

.border-img {
  border-radius: 10px;
}

.common {
  color: #ff0083;
}

.green {
  color: #6dff39;
}

.red {
  color: #f41e5e;
}

.white {
  color: #fff !important;
}

.blue {
  color: #133572;
}

.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}


@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  .main-footer {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  h1 {
    font-size: 2.5rem !important;
  }

  .ptb {
    padding: 30px 0;
  }

  .xs-0 {
    padding: 0px;
  }

  .main-mint,
  .main-banner {
    .inner-content {
      .modal-content {
        .for-padding {
          padding: 0 0px !important;
        }
      }
    }
  }

  .main-give {
    .right-side {
      margin-top: 30px !important;

      h5 {
        font-weight: 500;
      }

      li {
        margin-right: 2px !important;
      }
    }
  }
}

// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {

  h1 {
    font-size: 3rem !important;
  }

  .ptb {
    padding: 20px 0;
  }
}



iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}

button {
  cursor: pointer;
}

@media (max-width: 600px) {
  h2 {
    font-size: 30px !important;
  }

  .col-md-6 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-8 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-4 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}


@media(max-width:600px) {
  .modal.show .modal-dialog {
    max-width: 100% !important;
  }
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea:focus-visible {
  outline: none !important;

}

button,
input,
optgroup,
select,
textarea:focus {
  outline: none !important;

}

button.close {
  opacity: 1;
}



.btn-launch {
  padding: 6px 25px;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.28px;
  text-transform: uppercase;
  height: 42px;

  border-radius: 15px;
  background: #53FFEA;
  box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;


}


.btn-disconnect {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #313131;
  background: #111;
  box-shadow: 0px -7px 0px 0px rgba(255, 255, 255, 0.04) inset;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.28px;
  text-transform: uppercase;
  padding: 6px 25px;
  height: 42px;


}




.Appp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.containerr {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  width: 100vw;
}

.sectionn {
  flex-shrink: 0;
  flex-grow: 0;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
}

.vertical-scrolll {
  overflow-y: auto;
  overflow-x: hidden;
}

.horizontal-scrolll {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.itemm {
  flex: 0 0 auto;
  width: 200px;
  /* Adjust width as needed */
  height: 200px;
  /* Adjust height as needed */
  margin: 10px;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}


.modal-backdrop {
  background: rgba(0, 0, 0, 0.80);
  backdrop-filter: blur(5px);
}

.generic-modal {
  .modal-dialog {
    max-width: 500px !important;

    .modal-content {
      padding: 20px;
      border-radius: 12px;
      background: #FFF;

      .modal-header {
        border-radius: 12px;
        border: 1px solid #E6E6E6;
        background: #FFF;
        box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
        padding: 15px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .modal-title {
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
        }

        .btn-close {
          background: url("../src/assets/close-circle.svg");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          border: none;
          border: none;
          box-shadow: none;
          padding: 0;
          outline: none;
          opacity: unset;
          width: 24px;
          height: 24px;
          margin: 0;
        }
      }

      .modal-body {
        padding: 0px;

        .setmargin{
          margin-bottom: 16px;
        }
        

        .mainbtns {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          gap: 15px;

          .diabledbtn {
            border: 1px solid #121212;
            background: #000;
            box-shadow: 4px 4px 0px 0px #121212;
            display: flex;
            padding: 15px 40px;
            justify-content: center;
            align-items: center;
            color: #363636;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            width: 100%;
            text-transform: uppercase;
          }

          .dullbtn {
            border-radius: 15px;
            border: 1px solid #E6E6E6;
            background: #FFF;
            box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
            display: flex;
            padding: 18px 40px;
            justify-content: center;
            align-items: center;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
            width: 100%;
          }

          .brdrbtn {
            border: 1px solid #FFF;
            border-radius: 15px;
            background: #4CEAD7;
            box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
            display: flex;
            padding: 18px 40px;
            justify-content: center;
            align-items: center;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
            width: 100%;
          }
        }

        .commonbtn {
          border-radius: 15px;
          background: #53FFEA;
          box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
          border: none;
          display: flex;
          padding: 18px 40px;
          justify-content: center;
          align-items: center;
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          text-transform: uppercase;
          width: 100%;
        }

        .walletmains {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 15px;

          .walletinner {
            display: flex;
            padding: 50px 20px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 12px;
            border-radius: 12px;
            border: 1px solid #F2F2F2;
            background: #FFF;
            width: 100%;
            cursor: pointer;

            .walletpara {
              color: #000;
              text-align: center;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            &:hover {
              transition: 0.5s;
              border: 1px solid #E6E6E6;
              background: #FFF;
              box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
            }
          }
        }
      }
    }
  }
}

.dark-toast .Toastify__toast--default {
  background: #333;
  color: #fff;
}

.asdasdasdasd {
  font-size: 20px !important;
  margin-bottom: 10px;
}

.sdsdfadsfdsfsdf {
  margin-top: 10px;
  font-size: 18px !important;
}

.buttonblur {
  pointer-events: none;
  opacity: 0.4;
}

.sdjsgdfhsdgfh {
  // pointer-events: none;
  opacity: 0.4;
}

.sjdgsdhjgdsjf {
  color: #000 !important;
  margin-top: 20px !important;
}


@media(max-width:768px) {
  .sddsdsdsdsdsds {
    display: none !important;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


.loadertext {
  color: #FFF;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.1px;
  margin-top: 20px;
}

.flexdirectioncol {
  flex-direction: column !important;
}

.pagination .page-item:first-child .page-link {
  background-color: transparent !important;
  border: none !important;
  color: #fff !important;
}


.sjdasjashdjasdjgsa {
  text-align: left !important;
}


@media (max-width:991px) {
  .generic-modal .modal-dialog .modal-content .modal-body .walletmains {
    grid-template-columns: auto;
  }
}



.mycheckbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 19px;

  .innercheckboxpara {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 0 !important;

    a {
      color: #000 !important;
      text-decoration: underline !important;
    }
  }

  .innercheckbox {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .innercheckbox input {
    position: absolute;
    width: 0;
    left: 50px;
    height: 0;
    opacity: 0;
    cursor: pointer;
  }

  .innercheckbox .innercheckmark {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    transition: all 0.2s ease;
    border: 1px solid #000;
    background: rgba(255, 255, 255, 0.00);
  }

  .innercheckbox input:checked~.innercheckmark {
    background: #53FFEA;
    border: 1px solid transparent;
  }



  .innercheckbox .innercheckmark::after {
    position: absolute;
    display: block;
    content: "";
    left: 50%;
    top: 40%;
    width: 5.5px;
    height: 12px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    transition: all 0.2s ease;
  }

  .innercheckbox input:checked~.innercheckmark::after {
    opacity: 1;
    transition: all 0.2s ease;
  }
}


.howitworks-content {
  h5 {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }

  ul {
    li {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      list-style-type: disc;
      margin: 12px 0;
      margin-left: 30px;
    }
  }

  h6 {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }

  .para {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 15px 0;
  }

  .mycheckbox {
    justify-content: center !important;
    margin: 40px 0;
  }
}

.howitworksmodal {
  .modal-dialog {
    max-width: 674px !important;
  }

  .btn-accept {
    border-radius: 15px;
    background: #53FFEA;
    box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
    padding: 10px 10px 10px 12px;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    display: block;
    margin: 0 auto;
    max-width: 184px;
    width: 100%;
  }
}

.modal {
  background: rgba(0, 0, 0, 0.60);
  backdrop-filter: blur(5px);
}

// .forheightset {
//   position: relative;
//   z-index: 9;

//   .global-bg {
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: -1;
//     pointer-events: none;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
// }


