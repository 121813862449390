.forheightset {
    min-height: 100vh;
}

.main-banner {
    min-height: calc(419px - 79px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 9;
    overflow: hidden;
    padding-top: 44px;
    position: relative;

    .global-bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .banner-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 0;
        height: 419px;
        pointer-events: none;
        object-fit: cover;
    }

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            padding-right: 50px;
        }

        .rightcompaign-box {
            border-radius: 12px;
            border: 1px solid rgba(33, 33, 33, 0.50);
            background: rgba(44, 44, 44, 0.30);
            backdrop-filter: blur(42px);
            padding: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 20px;
            h6{
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; 
                text-transform: uppercase;
            }
            .inner-timer{
                display: flex;
                justify-content: center;
                align-items: center;
                span{
                    color: #51545A;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                    text-transform: uppercase;
                    margin: 0 11px;
                    display: flex;
                }
                .text{
                    h5{
                        color: #53FFEA;
                        font-size: 28px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 100%; 
                        text-transform: uppercase;
                        margin-bottom: 5px;
                        text-align: center;
                    }
                    p{
                        color: #8A8C90;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                        text-transform: uppercase;
                        text-align: center;
                    }
                }
            }
        }
    }

    .bannerheadings {
        max-width: 494px;
        position: relative;

        .bannerhead {
            color: #53FFEA;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.36px;
            text-transform: uppercase;

        }

        h2 {
            color: #FFF;
            font-size: 46px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: 0.92px;
            margin: 17px 0;
        }

        .bannerpara {
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-transform: capitalize;
            margin-bottom: 26px;
        }

        .twice-btns {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 30px;
        }

        .howitworks-btn {
            color: #FFF;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
        }

        .stake-btn {
            background: #FFF;
            padding: 15px 10px 15px 12px;
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 199px;
            width: 100%;
        }
    }
}

.tabsmain {
    position: relative;

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        color: #FFF !important;
        border-bottom: 1px solid #FFF !important;
        font-weight: 700px !important;
    }

    .nav-tabs {
        max-width: fit-content;
        border: none;
        margin-bottom: 56px;

        .nav-item {
            .nav-link {
                color: #A1A1A1;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 110%;
                display: flex;
                padding: 20px 10px;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                border: none;
                border-radius: 0px;
                width: 198px;
                border-bottom: 1px solid #3B3B3B;
            }
        }
    }
}

@media (min-width:1800px) {
    .main-banner {
        min-height: calc(600px - 79px);
    }

    .main-banner .banner-bg {
        height: 600px;
    }
}

@media (max-width:992px) {
    .main-banner {
        min-height: calc(520px - 79px);
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        z-index: 9;
        overflow: hidden;

        .banner-bg {
            display: none;
        }

        .banner-bg-mbl {
            display: block !important;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: -1;
            height: 520px;
            pointer-events: none;
            object-fit: cover;
        }
    }



    .main-banner .bannerheadings .bannerpara {
        font-size: 16px;
    }

    .main-banner .bannerheadings {
        max-width: 305px;
    }

    .tabsmain .nav-tabs .nav-item {
        padding-bottom: 1px;

    }

    .tabsmain .nav-tabs {
        flex-wrap: nowrap;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .tabsmain .nav-tabs .nav-item .nav-link {
        white-space: nowrap;
    }

    .tabsmain .nav-tabs .nav-item .nav-link {
        border-bottom: unset !important;
    }

    .tabsmain .nav-tabs .nav-item.show .nav-link,
    .tabsmain .nav-tabs .nav-link.active {
        border-bottom: 1px solid #fff !important;
    }



    .tabsmain .nav-tabs .nav-item .nav-link {
        width: 100%;
        font-size: 14px;
    }

    .tabsmain .nav-tabs {
        border-bottom: 1px solid #3B3B3B;
    }

    .tabsmain .nav-tabs {
        width: 100%;
        max-width: 100%;
    }

    .tabsmain .nav-tabs {
        margin-bottom: 30px;
        justify-content: space-between;
        align-items: center;
    }

    .tabsmain .nav-tabs .nav-item {
        flex: 1;
    }

    .tabsmain .nav-tabs .nav-item .nav-link {
        width: 100%;
    }

    .main-banner .parent {
        flex-direction: column;
        gap: 38px;
    }

    .main-banner .parent img {
        padding: 0;
    }

    .main-banner .bannerheadings .twice-btns {
        flex-direction: column;
    }

    .main-banner .bannerheadings h2 {
        font-size: 24px;
    }
}

.howitworks-btn {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}


@media (max-width:1450px) {
    .main-banner .custom-container {
        max-width: 1200px !important;
    }
}

.set-upper-line{
    padding-top: 20px;
    border-top: 1px solid #252525;
}