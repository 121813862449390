.main-footer {
    margin-top: 20px;
    border: 1px solid #1B1B1B;
    background: #000;
    padding: 25px 30px;

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            color: #9A9A9A;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
        }
    }
}

@media (max-width:600px) {
    .main-footer {
        padding: 29px 0px;
        border: none;
        border-top: 1px solid #1B1B1B;
    }
    .main-footer .parent {
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }
}