.main-navbar {
    height: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 999;
    background: #111;
    backdrop-filter: blur(12px);

    .navbar-brand {
        margin: 0;
    }

    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
    }

    .twice-btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 32px;

        .faqs-btn {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
        }
    }
}

@media(max-width:992px) {
    .bar-btn {
        display: block !important;
    }

    .main-navbar .twice-btns {
        display: none !important;
    }
}


.offcanvas-bar {
    min-width: 100% !important;
    background: #000;

    .offcanvas-header {
        background: #111;
        backdrop-filter: blur(12px);

        .btn-close {
            background: url("../../../assets/close-btn.svg") !important;
            box-shadow: none !important;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain !important;
            opacity: 1 !important;
        }
    }

    .offcanvas-body {
        padding: 15px !important;
        padding-top: 30px !important;

        .multi-btns {
            .faqs-btn {
                border-radius: 15px;
                border: 1px solid #313131;
                background: rgba(0, 0, 0, 0.50);
                box-shadow: 0px -5px 0px 0px rgba(255, 255, 255, 0.20) inset;
                padding: 17px 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-bottom: 20px;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
            }

            .btn-disconnect {
                width: 100%;

                h6 {
                    width: 100%;
                    text-align: center;
                }
            }

            .btn-launch {
                width: 100%;

                h6 {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}