.parentmain {
  .thirdclass {
    border-radius: 15px;
    background: rgba(248, 248, 248, 0.05);
    box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.02) inset;
    color: #414141;
    display: flex;
    width: 120px;
    padding: 13px 20px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    border: none;
  }

  .thirdclass2 {
    border-radius: 15px;
    background: #4CEAD7;
    box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
    display: flex;
    padding: 13px 20px;
    justify-content: center;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    align-items: center;
    border: none;
  }
  .parentbar {
    border-radius: 12px;
    border: 1px solid #252525;
    background: var(--Black, #000);
    padding: 25px;
    margin: 0 auto;
    margin-bottom: 163px;

    th {
      color: var(--Black, #fff);
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.28px;
      text-transform: uppercase;
      padding: 20px 20px;
      vertical-align: middle;
      border-bottom: 1px solid #212121;


      white-space: nowrap;
    }

    .newdiv {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .headtabel {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: 0.28px;
      text-transform: uppercase;
      height: 79px;
      padding: 10px 20px;
      border-bottom: 1px solid #1B1B1B;

      background: #0D0D0D;
      padding-right: 10px;
    }

    .headingtable {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      /* 25.2px */
      text-transform: uppercase;
      padding-bottom: 20px;
    }

    .tabletext {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: 0.1px;
    }

    .tablebody {
      border-bottom: 1px solid #1B1B1B;
      background: #000;
    }

    .secondtabletext {
      color: #aaa;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      /* 150% */
      letter-spacing: 0.28px;
    }

    .datatableone {
      height: 80px;
      padding: 10px 20px;
      border-bottom: 1px solid #212121;
      background: #090909;
    }

    .addclass {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      /* 150% */
      letter-spacing: 0.28px;
    }

    .secondclass {
      color: #ff8a00;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: 0.1px;
    }

    .secondclassagain {
      color: #08a321;

      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: 0.1px;
    }

    .thirdclass {
      border-radius: 15px;
      background: rgba(248, 248, 248, 0.05);
      box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.02) inset;
      color: #414141;
      display: flex;
      width: 120px;
      padding: 13px 20px;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      border: none;
    }

    .thirdclass2 {
      border-radius: 15px;
      background: #4CEAD7;
      box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
      display: flex;
      padding: 13px 20px;
      justify-content: center;
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      align-items: center;
      border: none;
    }

    .maintablehaed {
      background: #090909;
      border-radius: 12px;
      border: 1px solid #212121;


      table {
        width: 100%;
      }
    }
  }

  .Paginationlattable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 13px 20px 14px 20px;
    background: #121212;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    backdrop-filter: blur(3px);


    .showclass {
      color: #a2a4a6;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      /* 14px */
    }

    .page-item:not(:first-child) .page-link {
      color: #a2a4a6;
      background-color: unset;
      border: unset;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      /* 15px */
      display: flex;
      padding: 6px 11px;
      gap: 10px;
    }



    .pagination {
      margin-bottom: unset;
      display: flex;
      align-items: center;
    }

    .page-link:focus {
      box-shadow: none !important;
    }

    .active>.page-link {
      border-radius: 5px;
      background: #53FFEA !important;
      color: #002D27 !important;
    }

    .svgclasss {
      padding: 6px;
    }
  }

  .mainheadphone {
    border-radius: 12px;
    border: 1px solid #252525;
    
    background: #000;
    padding: 25px;
    margin-bottom: 174px;

    .headingstalking {
      color: #fff;
      font-size: 18px !important;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      /* 25.2px */
      text-transform: uppercase;
      padding-bottom: 20px;
    }

    .cardone {
      padding: 15px;
      border: 1px solid #212121;
      margin-bottom: 15px;
      background: #090909;
      border-radius: 16px;
    }

    .dateclass {
      color: #8e8e8e;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      /* 14.4px */
      padding-bottom: 5px;
    }

    .dateclasstwo {
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      /* 14.4px */
    }

    .amount {
      color: #8e8e8e;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      /* 14.4px */
      padding-bottom: 5px;
    }

    .digits {
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      /* 14.4px */
      letter-spacing: 0.1px;
    }

    .cooldown {
      color: #8e8e8e;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      /* 14.4px */
      letter-spacing: 0.24px;
      padding-bottom: 5px;
    }

    .timer {
      color: #ff8a00;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: 0.1px;
      padding-bottom: 20px;
    }

    .withdrawbutton {
      color: #363636;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      padding: 10px 30px;
      border: 1px solid #121212;
      background: #000;
      box-shadow: 2px 2px 0px 0px #121212;
      width: 100%;
    }

    .withdrawbutton1 {
      color: #fff;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      padding: 10px 20px;
      border: 1px solid #fff;
      background: #000;
      box-shadow: 2px 2px 0px 0px #fff;
      width: 100%;
    }

    .flexclass {
      display: flex;
      justify-content: space-between;
      align-self: stretch;
      padding-bottom: 20px;
    }

    .headofcards {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}

.Paginationlattable2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 13px 11px;
  border: 1px solid #fff;
  background: #000;
  backdrop-filter: blur(3px);
  background: #000;
  backdrop-filter: blur(3px);
  margin-top: 20px;

  .showclass {
    color: #a2a4a6;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    /* 14px */
  }

  .page-item:not(:first-child) .page-link {
    color: #a2a4a6;
    background-color: unset;
    border: unset;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    /* 15px */
    display: flex;
    padding: 6px 11px;
    gap: 10px;
  }

  .pagination {
    margin-bottom: unset;
    display: flex;
    align-items: center;
  }

  .active>.page-link {
    color: #000 !important;
    border: unset;
    border-radius: unset;
    background: #fff !important;
  }

  .svgclasss {
    padding: 6px;
  }
}

.generic-modal {
  .paragraphmodal {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 15.4px */
  }

  .headingmodal {
    color: #fff;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 44px */
    text-transform: uppercase;
    padding-top: 15px;
  }

  .headmodal {
    margin: 0 auto;
    padding: 30px 0px;
    background: url('../../../src/assets/modalcommonbg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 16px;
  }

  .divmid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .feediv {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 14.4px */
  }

  .numdiv {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 14.4px */
    letter-spacing: 0.24px;
  }

  .walletpara1 {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    padding-top: 10px;
    padding-bottom: 50px;
  }
}

.stakingmodals {
  .modal-body {
    .forbrdrs {
      border: 1px solid #fff !important;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;
      padding: 30px 0px;
      width: 100%;
      margin-bottom: 30px;
    }

    .happy {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .formsrgin {
        // margin-top: 30px;
      }

      h6 {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-top: 20px;
        margin-bottom: 0px;
      }

      .wrong {
        margin-top: 40px;
      }

      p {
        color: #2F2F2F;
        margin-bottom: 20px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
      }
    }
  }
}

@media (max-width: 850px) {
  .parentmain .Paginationlattable{
    border-radius: 12px !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .parentmain {
    .mainheadphone {
      display: block !important;
    }

    .parentbar {
      display: none;
    }

    .secondclass {
      color: #ff8a00;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: 0.1px;
    }

    .secondclassagain {
      color: #08a321;

      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: 0.1px;
    }
  }
}


.thirdclass.lklklklklklk {
  color: #363636;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 10px 30px;
  border: 1px solid #121212;
  background: #000;
  box-shadow: 2px 2px 0px 0px #121212;
  outline: none;
}

.thirdclass2.lklklklklklk {
  padding: 10px 30px;
  border: 1px solid #fff;
  background: #000;
  box-shadow: 2px 2px 0px 0px #fff;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  outline: none;
}


.parentmain .stakingstatbox {
  padding: 60px 20px;
}

.parentmain .stakingstatbox .wrapper .whitetext {
  font-size: 18px;
  width: unset;
  text-align: center;
}


.stakingstatbox {
  border-radius: 12px;
  border: 1px solid #252525;
  background: rgba(0, 0, 0, 0.70);
  backdrop-filter: blur(12px);
  padding: 60px 394px;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .whitetext {
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      margin-top: 20px;
      margin-bottom: 45px;
      width: 478px;
    }

    .waletconnectss {
      border: none;
      border-radius: 15px;
      background: #53FFEA;
      box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
      padding: 18px 25px;
      color: var(--Black, #000);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: 0.28px;
      text-transform: uppercase;
    }
  }
}

.unstaking-main-section {
  padding-bottom: 100px;
}