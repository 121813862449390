.statsmain {
    margin-top: 44px;
    margin-bottom: 22px;

    .statshead {
        color: #FFF;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 30px;
    }

    .statscardtwo {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        margin-bottom: 10px;

        .statcard {
            display: flex;
            padding: 40px 25px;
            // flex-direction: column;
            align-items: flex-start;
            position: relative;
            gap: 20px;

            border-radius: 12px;
            border: 1px solid #252525;
            background: rgba(0, 0, 0, 0.70);
            backdrop-filter: blur(12px);

            .statcardmainimg {
                position: absolute;
                bottom: 0;
                right: 0;
            }

            .statcardpara {
                color: #868686;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                // text-transform: uppercase;
                // padding: 15px 0px;
                margin-bottom: 10px;
                z-index: 99;
            }

            .statcardhead {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
                z-index: 99;
            }
        }
    }

    .statscardthree {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        margin-bottom: 20px;

        .statcard {
            border: 1px solid #1B1B1B;
            background: rgba(13, 13, 13, 0.30);
            // box-shadow: 2px 2px 0px 0px #FFF;
            display: flex;
            padding: 40px 25px;
            // flex-direction: column;
            gap: 20px;
            align-items: flex-start;
            position: relative;

            .statcardmainimg {
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: -9;
            }

            .statcardpara {
                color: #868686;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                // text-transform: uppercase;
                // padding: 15px 0px;
                margin-bottom: 10px;
                z-index: 99;
            }

            .statcardhead {
                color: #FFF;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
                z-index: 99;
            }
        }
    }

    .parentsssss {
        .progress {
            border-radius: 10px;
            background: #333;

            .progress-bar {
                border-radius: 10px;
                background: #53FFEA;
            
            }
        }
        .progress-barrrr{
            p{
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%; 
                text-transform: uppercase;
                margin-top: 10px;
                span{
                    color: #8A8C90;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                    text-transform: uppercase;
                }
            }
        }
    }
}





@media(max-width:992px) {
    .statsmain .statscardtwo {
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .statsmain .statscardthree {
        grid-template-columns: 1fr;
        gap: 15px;
    }

    .statsmain .statshead {
        font-size: 20px;
        margin-bottom: 25px;
    }

    .statsmain .statscardtwo .statcard .statcardhead {
        font-size: 20px;
    }

    .statsmain .statscardthree .statcard .statcardhead {
        font-size: 20px;
    }

    .paddmobilezero {
        padding: 0px;
    }

    .statsmain .statscardtwo .statcard {
        flex-direction: column;
        padding: 30px 15px;

    }

    .statsmain .statscardtwo .statcard .statcardpara {
        font-size: 12px;
    }

    .statsmain .statscardtwo .statcard .statcardhead {
        font-size: 14px;
    }

    .main-banner .bannerheadings .stake-btn {
        max-width: 100%;
    }
}

@media (max-width:600px){
    .statsmain .statscardtwo{
        grid-template-columns: 1fr;
    }
}

.colorfoffff{
    font-size: 15px;
}

.offcoloreher{
    color: #8A8C90;
    font-weight: 700;
}