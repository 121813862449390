.termofuse_section{
    position: relative;
    z-index: 99;
    padding-top: 60px;
    padding-bottom: 84px;
    background-color: #fff;
    .termofuse-bg{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        object-fit: cover;
        pointer-events: none;
        height: 313px;
    }
    .main-heading{
        padding-bottom: 214px;
        h6{
            color: #53FFEA;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; 
            letter-spacing: 0.36px;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        h4{
            color: #FFF;
            font-size: 46px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; 
            letter-spacing: 0.92px;
        }
    }
    .bottom-content{
        p{
            color: #303030;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            margin: 20px 0;
            span{
                font-weight: bold;
                text-decoration: underline !important;
            }
            a{
                color: #000 !important;
                text-decoration: underline !important;
                font-weight: bold;
            }
        }
    }
}

@media (max-width:991px){
    .termofuse_section .main-heading h4{
        font-size: 28px;
    }
    .termofuse_section .bottom-content p{
        font-size: 16px;
    }
}