.mystaking {
  padding-bottom: 100px;

  .stakingstatbox {
    border-radius: 12px;
    border: 1px solid #252525;
    background: rgba(0, 0, 0, 0.70);
    backdrop-filter: blur(12px);
    padding: 60px 394px;

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .whitetext {
        color: #fff;
        font-size: 20px;
        text-align: center;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-top: 20px;
        margin-bottom: 45px;
        width: 478px;
      }

      .waletconnectss {
        border: none;
        border-radius: 15px;
        background: #53FFEA;
        box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
        padding: 18px 25px;
        color: var(--Black, #000);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: 0.28px;
        text-transform: uppercase;

      }
    }
  }

  .mystakingmain {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;

    .leftstaking {
      border-radius: 12px;
      border: 1px solid #252525;
      background: var(--Black, #000);
      max-width: 530px;
      width: 100%;
      padding: 30px;
      position: sticky;
      top: 15px;

      .leftstakinghead {
        color: #fff;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        border-bottom: 1px solid #1e1e1e;
        width: 100%;
        padding-bottom: 30px;
        margin-bottom: 30px;
      }

      .rewardbox {
        border: 1px solid #1b1b1b;
        background: #0d0d0d;
        padding: 20px;

        .reard {
          color: #868686;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          text-transform: uppercase;
          margin-bottom: 7px;
        }

        h4 {
          color: #fff;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          text-transform: uppercase;
          margin-bottom: 24px;
        }

        .claimssss {
          border: 1px solid #151515;
          background: rgba(255, 255, 255, 0.2);
          padding: 10px 30px;
          width: 100%;
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
        }
      }
    }

    .rightstaking {
      border-radius: 12px;
      border: 1px solid #252525;
      background: var(--Black, #000);
      padding: 25px;
      width: 100%;

      .rightstakinghead {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
    }
  }
}

.stakingmodals {
  .modal-body {
    .forbrdrs {
      border: 1px solid #fff !important;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;
      padding: 30px 156px;
      width: 100%;
      margin-bottom: 30px;
    }

    .forbrdr {
      border: 1px solid #fff !important;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;
      padding: 30px 156px;
      width: 100%;
    }

    .stakecard {
      background: url('../../../src/assets/modalcommonbg.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 16px;
      padding: 30px 0px;
      width: 100%;

      .textcard {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .parent {
          width: 100%;

          input {
            background: transparent;
            padding: 0px;
            color: #fff;
            border: none;
            height: 44px;
            text-align: center;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            text-transform: uppercase;
            width: 100%;

            &::placeholder {
              color: #FFF;
              text-align: center;
              font-size: 40px;
              font-style: normal;
              font-weight: 700;
              line-height: 110%;
              text-transform: uppercase;
            }
          }

          .uppertext {
            color: #fff;

            text-align: center;
            // white-space: nowrap;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 110%;
            margin-bottom: 15px;
            position: relative;
          }

          .para {
            color: #FFF;
            text-align: center;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            text-transform: uppercase;
            word-break: break-all;
          }

          .max {
            color: #fff;

            text-align: right;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: 0.24px;
            position: absolute;
            top: 60px;
            right: 15px;
          }
        }
      }
    }

    .textparent {
      margin: 30px 0px;

      .innercontent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        .wallet {
          color: #000;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
        }

        .textpara {
          color: #000;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          letter-spacing: 0.24px;
        }
      }
    }

    .yellowbox {
      border: 1px solid rgba(255, 138, 0, 0.3);
      background: rgba(255, 138, 0, 0.15);
      padding: 20px;
      width: 100%;
      margin-bottom: 30px;
      border-radius: 16px;

      .cool {
        color: #ff8a00;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: 0.24px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .yellowpara {
        color: #ff8a00;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: 0.24px;
      }
    }

    .mainbtns {
      .btndisable {
        border: 1px solid #121212;
        background: #000;
        box-shadow: 4px 4px 0px 0px #121212;
        display: flex;
        padding: 15px 40px;
        justify-content: center;
        align-items: center;
        color: #363636;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;
        width: 100%;
      }
    }

    .happy {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .formsrgin {
        // margin-top: 30px;
      }

      h6 {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-top: 20px;
        margin-bottom: 0px;
      }

      .wrong {
        margin-top: 40px;
      }

      p {
        color: #2F2F2F;
        margin-bottom: 20px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
      }
    }
  }
}

@media (max-width: 1100px) {
  .mystaking .mystakingmain {
    flex-direction: column;
  }

  .mystaking .mystakingmain .leftstaking {
    position: unset;
    top: unset;
    max-width: 100%;
  }
}

@media (max-width: 992px) {
  .mystaking .mystakinghead {
    font-size: 24px;
  }

  .mystaking .mystakingmain .leftstaking .leftstakinghead {
    font-size: 24px !important;
  }

  .mystaking .mystakingmain .leftstaking {
    padding: 20px;
  }

  .mystaking .mystakingmain .leftstaking .stakingdetailmain {
    flex-direction: column;
    gap: 25px;
  }

  .mystaking .mystakingmain .leftstaking .rewardsmain {
    padding: 15px;
    flex-direction: column;
    gap: 25px;

    .rewardsleft {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .mystaking .mystakingmain .leftstaking .rewardsmain .rewardsleft .rewardleftpara {
    text-align: center;
  }

  .mystaking .mystakingmain .leftstaking .rewardsmain .rewardsleft .rewardlefthead {
    text-align: center;
  }

  .mystaking .mystakingmain .leftstaking .rewardsmain .rewardsrightparas .yellowpara {
    text-align: center;
  }

  .mystaking .mystakingmain .leftstaking .rewardsmain .rewardsrightparas .yellowhead {
    text-align: center;
  }

  .mystaking .mystakingmain .leftstaking .rewardsmain .rewardsright .restakebtn {
    width: 100%;
    border: 1px solid #151515;
    background: #0d0d0d;
    padding: 15px 30px;
  }

  .mystaking .mystakingmain .leftstaking .rewardsmain .rewardsright .claimbtn {
    width: 100%;
  }

  .mystaking .mystakingmain .leftstaking .rewardsmain .rewardsright {
    justify-content: space-between;
    width: 100%;
    gap: 10px;
  }

  .mystaking .mystakingmain .rightstaking .mytable {
    display: none;
  }

  .mystaking .mystakingmain .rightstaking .mytablemobile {
    display: block !important;

    .mbltable {
      //   border: 1px solid #fff;
      border: 1px solid #212121;
      border-radius: 16px;
      background: #090909;
      display: flex;
      padding: 15px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 19px;
      margin-bottom: 10px;

      .mbltableinner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        .mbltabledetail {
          width: 100%;

          .mblpara {
            color: #8e8e8e;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            margin-bottom: 5px;
          }

          .mblhead {
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
          }

          .mblheadlink {
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: 0.1px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
  }

  .mystaking .mystakingmain .rightstaking {
    padding: 20px;
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 20px;
    background: #121212;
    backdrop-filter: blur(3px);
    margin-top: 20px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .right-f .active>.page-link,
    .right-f .page-link.active {
      border-radius: 5px;
      background: #53FFEA !important;
      color: #002D27 !important;
    }

    .right-f {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .active>.page-link,
      .page-link.active {
        border-radius: 5px;
        background: #53FFEA !important;
        color: #002D27 !important;
      }

      .page-link:focus {
        box-shadow: none;
        background-color: unset;
      }

      .page-link:hover {
        box-shadow: none;
        background-color: unset;
      }

      .page-link {
        border: none;
        color: #8C8C8C;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        background-color: transparent;

        &.active {
          background: #fff !important;
          color: #000 !important;
        }
      }

      .page-item:first-child .page-link {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
        color: #a2a4a6;
      }
    }
  }

  .mystaking .stakingstatbox {
    padding: 60px 20px;
  }

  .mystaking .stakingstatbox .wrapper .whitetext {
    font-size: 18px;
    width: unset;
    text-align: center;
  }
}