.faqs-section {
    position: relative;
    z-index: 99;
    padding-top: 93px;
    padding-bottom: 63px;
    min-height: calc(100vh - 90px);
    overflow: hidden;
    .global-bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

    .banner-bg {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        z-index: 1;
        width: 100%;
        object-fit: cover;
    }

    .faqs-content {
        max-width: 1180px;
        width: 100%;
        margin: 0 auto;

        h4 {
            color: #fff;
            font-size: 46px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            margin-bottom: 18px;
        }

        p {
            color: #FFF;
            font-size: 21px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-transform: capitalize;
            margin-bottom: 70px;

            a {
                color: #FFF;
                font-size: 21px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-decoration: underline;
                text-transform: capitalize;
            }
        }
    }

    .innerfaq {
        max-width: 1210px;
        padding: 0px 15px;
        width: 100%;
        margin: 0 auto;

        .accordion {
            border: none;
            border-radius: 0px;
            margin: 0;
            padding: 0;
            outline: none;
            background: transparent;
            box-shadow: none;

            .active {
                background: #111 !important;
            }

            .accordion-item {
                border-radius: 0px;
                margin: 0;
                outline: none;
                box-shadow: none;
                border: 1px solid #191919;
                background: #000;
                margin-bottom: 15px;

                .accordion-header {
                    border: none;
                    border-radius: 0px;
                    margin: 0;
                    padding: 0;
                    outline: none;
                    background: transparent;
                    box-shadow: none;

                    .accordion-button:not(.collapsed)::after {
                        background: url("../../assets/Remove_Minus.svg");
                        background-repeat: no-repeat;
                        background-position: unset;
                        background-size: cover;
                        margin: 0;
                        padding: 0;
                        width: 24px;
                        height: 24px;
                    }

                    .accordion-button::after {
                        background: url("../../assets/Add_Plus.svg");
                        background-repeat: no-repeat;
                        background-position: unset;
                        background-size: cover;
                        margin: 0;
                        padding: 0;
                        width: 24px;
                        height: 24px;
                    }

                    .accordion-button {
                        border: none;
                        border-radius: 0px;
                        margin: 0;
                        outline: none;
                        background: transparent;
                        box-shadow: none;
                        padding: 25px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 30px;

                        p {
                            color: #fff;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 130%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 10px;

                            span {
                                color: #fff;
                                font-weight: 500px;
                            }
                        }
                    }
                }
            }

            .accordion-body {
                border: none;
                border-radius: 0px;
                margin: 0;
                outline: none;
                background: transparent;
                box-shadow: none;
                padding: 0px 25px 25px 25px;
                max-width: 866px;
                width: 100%;
                color: #fff;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;

                ul {
                    li {
                        color: #fff;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        list-style-type: disc;
                        margin-left: 25px;
                        padding: 5px 0;
                    }
                }

                a {
                    color: #fff !important;
                    text-decoration: underline !important;
                }
            }
        }
    }

    .bottom-resource {
        border: 1px solid #191919;
        background: #111;
        padding: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 15px;
        max-width: 700px;
        width: 100%;
        margin: 0 auto;
        margin-top: 40px;

        p {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
        }
        a{
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%; 
            text-decoration: underline !important;
            text-align: center;
        }
    }
}

.light-theme .faqs-section .innerfaq .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
    // background: url("../../public/assets/minus-icon-light.svg");
    background-repeat: no-repeat;
    background-position: unset;
    background-size: cover;
    margin: 0;
    padding: 0;
    width: 24px;
    height: 24px;
}

.light-theme .faqs-section .innerfaq .accordion .accordion-item .accordion-header .accordion-button::after {
    // background: url("../../public/assets/plus-icon-light.svg");
    background-repeat: no-repeat;
    background-position: unset;
    background-size: cover;
    margin: 0;
    padding: 0;
    width: 24px;
    height: 24px;
}

@media (max-width:600px) {
    .faqs-section {
        padding-top: 129px;
    }

    .faqs-section .faqs-content h4 {
        font-size: 39px;
    }
}